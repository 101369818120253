@import '@byted-motor/pc-components/dist/styles/variable.scss';
.button-wrapper {
  height: 34px;
  width: 34px;
  text-align: center;
  padding: 6px;
  &:not(:global(.swiper-button-disabled)) .swiper-button:hover {
    background: #616370;
    color: #f2f4fa;
  }
  &:global(.swiper-button-disabled) {
    display: inline-block;
  }
  &:global(.swiper-button-disabled) .swiper-button {
    cursor: default;
    color: #c9cbd6;
  }
}
.swiper-button {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  color: #1f2129;
  &:global(.light) {
    background: #fff;
  }
  &:global(.gray) {
    background: #f7f8fc;
  }
}