@import '@byted-motor/pc-components/dist/styles/variable.scss';
.dcd-modal {
  animation: modal-enter 0.13s;
  @apply tw-fixed
  tw-inset-0
  tw-bg-common-black
  tw-bg-opacity-50;
  &:global(.leave) {
    animation: modal-leave 0.13s;
    animation-fill-mode: forwards;
  }
}
@keyframes modal-enter {
  from {
    background-color: rgba(247, 248, 252, 0);
  }
  to {
    background-color: rgba(247, 248, 252, 0.5);
  }
}
@keyframes modal-leave {
  from {
    background-color: rgba(247, 248, 252, 0.5);
  }
  to {
    background-color: rgba(247, 248, 252, 0);
  }
}

.dialog-wrapper {
  animation: enter 0.13s;
  @apply tw-fixed
  tw-inset-0
  tw-text-center;
  &:after {
    content: '';
    @apply tw-inline-block
    tw-align-middle
    tw-h-full;
  }
}

.dialog-content {
  box-shadow: 0 30px 45px 0 rgba(31, 33, 41, 0.24);
  animation: enter 0.13s;
  @apply tw-relative
  tw-inline-block
  tw-align-middle
  tw-text-left
  tw-rounded-4;
  &:global(.leave) {
    animation: leave 0.13s;
  }

  &.space-small {
    @apply tw-bg-common-black tw-pt-42 tw-px-10 tw-pb-12;
  }

  &.space-medium {
    @apply tw-bg-white tw-pt-46 tw-px-32 tw-pb-24;
  }
}

@keyframes enter {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes leave {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

.dialog-header {
  @apply tw-flex tw-absolute tw-z-100;

  &.space-small {
    @apply tw-text-color-gray-400 tw-top-10 tw-left-10 tw-right-10;

    .dialog-close-btn {
      @apply tw-text-color-gray-600 hover:tw-text-white;
    }
  }

  &.space-medium {
    @apply tw-text-common-black tw-top-24 tw-left-32 tw-right-32;

    .dialog-close-btn {
      @apply tw-text-common-black;
      //@apply tw-text-color-gray-800 hover:tw-text-common-black;
    }
  }
}

.dialog-title {
  @apply tw-flex-auto
  tw-h-22
  tw-leading-22
  tw-text-16
  tw-font-semibold
  tw-truncate;
}

.dialog-close-btn {
  @apply tw-h-22
  tw-leading-22
  tw-text-16
  tw-cursor-pointer
  tw-font-normal;
  :global(.DCD_Icon) {
    @apply tw-text-18
    tw-align-bottom;
  }
}