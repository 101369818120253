@import '@byted-motor/pc-components/dist/styles/variable.scss';
.floor-title {
  display: flex;
  width: 100%;
  align-items: center;
}
.title {
  font-family: PingFangSC;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1f2129;
}
.nav-wrap {
  margin-left: 8px;
  flex: 1;
  display: flex;
}
.nav-item {
  padding: 0 8px;
  height: 20px;
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #606370;
  border-right: 0.5px solid #979aa8;
}
.nav-item:last-child {
  border-right: none;
}
.label-wrap {
  display: flex;
  align-items: center;
}
.nav-more {
  position: relative;
  top: 0px;
}
.nav-more:after {
  content: '';
  width: 0;
  height: 0;
  margin-left: 3px;
  display: inline-block;
  position: relative;
  top: -1px;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 5px solid #606370;
  vertical-align: middle;
}
.slier {
  line-height: 0;
  vertical-align: middle;
}
.slider-num {
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1f2129;
}
.slider-btn {
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 4px;
  border-radius: 4px;
  background-color: #e6e8f2;
}
.slider-action {
  position: relative;
  width: 100px;
  height: 34px;
}