@import '@byted-motor/pc-components/dist/styles/variable.scss';
.avatar-wrap {
  width: 100%;
  position: absolute;
  bottom: -25px;
  left: 0;
  padding-left: 10px;
  padding-right: 8px;
  display: flex;
  align-items: flex-end;
}
.avatar-wrap .name {
  flex: 1;
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin-left: 8px;
}