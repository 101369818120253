@import '@byted-motor/pc-components/dist/styles/variable.scss';
.title-wrapper {
  display: flex;
  align-items: center;
}

.module-title {
  font-weight: 500;
  height: 28px;
  line-height: 28px;
  @apply tw-text-16 lg:tw-text-18 xl:tw-text-20;
}