@import '@byted-motor/pc-components/dist/styles/variable.scss';
.swiper-button2 {
  position: absolute;
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(31, 33, 41, 0.8);
  color: #fff;
  display: inline-block;
  z-index: 100;
  top: 0;
  bottom: 0;
  margin: auto;

  &:global(.prev) {
    left: 8px;
  }

  &:global(.next) {
    right: 8px;
  }
  &:not(:global(.swiper-button-disabled)):hover {
    background-color: #ffcc32;
    color: #000;
  }
  &:global(.swiper-button-disabled) {
    display: inline-block;
    cursor: default;
    background-color: rgba(31, 33, 41, 0.2);
  }
}