.bullet-wrap{
  display:flex;
  align-items:flex-end;
  justify-content:flex-end;
  height:4px;
  position:absolute;
  right:4px;
  bottom:8px;
  width:100px;
  z-index:1;
  z-index:100
}

@media (min-width: 1920px){
  .bullet-wrap{
    bottom:-10px
  }
}

.bullet{
  --tw-bg-opacity:1;
  background-color:rgba(201, 203, 214, var(--tw-bg-opacity));
  cursor:pointer;
  height:3px;
  margin-right:4px;
  width:12px
}

.bullet.active{
  --tw-bg-opacity:1;
  background-color:rgba(31, 33, 41, var(--tw-bg-opacity));
  height:4px;
  width:16px
}

.bullet:hover{
  height:5px
}

.slide{
  transition:.3s ease-in-out;
  display:-ms-grid;
  display:grid;
  height:100%;
  opacity:0;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  -moz-column-gap:12px;
       column-gap:12px;
  row-gap:9px;
  -ms-grid-columns:minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr);
  grid-template-columns:repeat(3, minmax(0, 1fr));
  -ms-grid-rows:minmax(0, 1fr) 9px minmax(0, 1fr);
  grid-template-rows:repeat(2, minmax(0, 1fr))
}

.slide > *:nth-child(1){-ms-grid-row:1;-ms-grid-column:1
}

.slide > *:nth-child(2){-ms-grid-row:1;-ms-grid-column:3
}

.slide > *:nth-child(3){-ms-grid-row:1;-ms-grid-column:5
}

.slide > *:nth-child(4){-ms-grid-row:3;-ms-grid-column:1
}

.slide > *:nth-child(5){-ms-grid-row:3;-ms-grid-column:3
}

.slide > *:nth-child(6){-ms-grid-row:3;-ms-grid-column:5
}

.slide.active{
  opacity:1;
  z-index:10
}

.banner{
  position:relative;
  -ms-grid-column-span:20;
  grid-column:span 20 / span 20
}

@media (min-width: 1920px){
  .banner{
    -ms-grid-column-span:24;
    grid-column:span 24 / span 24
  }
}