.type-list{
  display:flex;
  height:90px
}

@media (min-width: 1680px){
  .type-list{
    height:80px;
    padding-left:16px;
    padding-right:16px
  }
}

.whole{
  height:100%;
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  align-items:center;
  flex:1 1 auto
}

@media (min-width: 1680px){
  .whole{
    flex-wrap:nowrap;
    align-items:flex-end;
    padding-bottom:20px
  }
}

.icon{
  position:relative;
  width:68px;
  height:32px;
  background-repeat:no-repeat;
  cursor:pointer;
  background-position:0 0;
  background-size:1174px 112px;
  display:block;
  flex:none;
  margin-bottom:-30px;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x:0.65;
  --tw-scale-y:0.65
}

@media (min-width: 1280px){
  .icon{
    margin-bottom:-28px;
    --tw-scale-x:0.86;
    --tw-scale-y:0.86
  }
}

@media (min-width: 1680px){
  .icon{
    display:inline-block;
    margin-bottom:0px;
    --tw-scale-x:0.96;
    --tw-scale-y:0.96
  }
}

.value{
  color:#1f2129;
  white-space:nowrap;
  display:block;
  font-size:14px;
  text-align:center;
  width:100%
}

@media (min-width: 1280px){
  .value{
    font-size:16px
  }
}

@media (min-width: 1680px){
  .value{
    display:inline;
    margin-left:4px;
    text-align:left
  }
}