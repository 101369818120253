@import '@byted-motor/pc-components/dist/styles/variable.scss';
.upper {
  animation-duration: 0.2s;
  animation-name: upper-enter;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes upper-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.lower {
  animation-duration: 0.2s;
  animation-name: lower-enter;
  animation-timing-function: ease;
  animation-delay: 0.4s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes lower-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.big {
  position: relative;
  @apply tw-col-span-3 2xl:tw-col-span-2 tw-row-span-2 tw-relative tw-overflow-hidden;
}

.small {
  @apply tw-overflow-hidden tw-relative tw-opacity-0 tw-hidden 2xl:tw-block;
}

.ad-tag {
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.298459);
  color: rgba(255, 255, 255, 0.800508);
  width: 26px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ad-tag::after {
  content: "广告";
  position: absolute;
  font-size: 12px;
  transform: scale(0.8);
  transform-origin: center;
}