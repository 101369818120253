@import '@byted-motor/pc-components/dist/styles/variable.scss';
.enter {
  animation-name: enter;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
}
@keyframes enter {
  from {
    opacity: 0;
    z-index: 1;
  }
  to {
    opacity: 1;
    z-index: 9;
  }
}
.leave {
  animation-name: leave;
  animation-duration: .3s;
  animation-timing-function: ease-in-out;
}
@keyframes leave {
  from {
    opacity: 1;
    z-index: 9;
  }
  to {
    opacity: 0;
    z-index: 1;
  }
}

.renderItem-list-wrap {
  position: relative;
  height: 60px;
}
.renderItem-list {
  display: flex;
}
.renderItem-list.active {
  opacity: 1;
}
.renderItem-title {
  width: 45px;
  padding: 10px 0 0 0;
  font-size: 16px;
  color: #1f2129;
}
.box {
  flex: 1;
  overflow: hidden;
  height: 58px;
  position: relative;
}