.upper{
  animation-duration:.2s;
  animation-name:upper-enter;
  animation-timing-function:ease;
  animation-delay:.2s;
  animation-fill-mode:forwards;
  opacity:0
}

@keyframes upper-enter{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

.lower{
  animation-duration:.2s;
  animation-name:lower-enter;
  animation-timing-function:ease;
  animation-delay:.4s;
  animation-fill-mode:forwards;
  opacity:0
}

@keyframes lower-enter{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

.big{
  position:relative;
  overflow:hidden;
  position:relative;
  -ms-grid-column-span:3;
  grid-column:span 3 / span 3;
  -ms-grid-row-span:2;
  grid-row:span 2 / span 2
}

@media (min-width: 1920px){
  .big{
    -ms-grid-column-span:2;
    grid-column:span 2 / span 2
  }
}

.small{
  display:none;
  opacity:0;
  overflow:hidden;
  position:relative
}

@media (min-width: 1920px){
  .small{
    display:block
  }
}

.ad-tag{
  position:absolute;
  right:8px;
  bottom:8px;
  border-radius:2px;
  background:rgba(0,0,0,.298459);
  color:rgba(255,255,255,.800508);
  width:26px;
  height:16px;
  display:flex;
  align-items:center;
  justify-content:center
}

.ad-tag::after{
  content:"广告";
  position:absolute;
  font-size:12px;
  transform:scale(0.8);
  transform-origin:center
}