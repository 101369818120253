@import '@byted-motor/pc-components/dist/styles/variable.scss';
.type-list {
  @apply tw-h-90 xl:tw-h-80 tw-flex xl:tw-px-16;
}

.whole {
  height: 100%;
  display: flex;
  justify-content: center;
  @apply tw-flex-auto tw-flex-wrap xl:tw-flex-nowrap tw-items-center xl:tw-items-end xl:tw-pb-20;
}

.icon {
  position: relative;
  width: 68px;
  height: 32px;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: 0 0;
  background-size: 1174px 112px;
  @apply tw-transform
  xl:tw-scale-96
  xl:tw--mb-0
  md:tw-scale-86
  md:tw--mb-28
  tw-scale-65
  tw--mb-30
  tw-flex-none
  tw-block
  xl:tw-inline-block;
}

.value {
  color: #1f2129;
  white-space: nowrap;
  @apply tw-text-14
  md:tw-text-16
  tw-text-center
  xl:tw-text-left
  tw-block
  tw-w-full
  xl:tw-inline
  xl:tw-ml-4;
}