@import '@byted-motor/pc-components/dist/styles/variable.scss';
.ad-row {
  display: flex;
  justify-content: space-between;
}
.ad-banner {
  position: relative;
  flex: 1;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
}
.ad-text {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
  width: 26px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
}
