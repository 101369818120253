@import '@byted-motor/pc-components/dist/styles/variable.scss';
.new-car-list {
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: scroll;
  height: 402px;
  overscroll-behavior: none;
  @media (min-width:1440px) {
    height: 382px;
  }
  @media (max-width: 1279px) {
    height: 360px;
  }
}
.new-car {
  @apply tw-mt-12 lg:tw-mt-24;
  position: relative;
}
.new-car:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 40px;
  background-image: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0),
                  #f2f4fa
  );
  pointer-events: none;
}
.new-car-list::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}
.new-car-list::-webkit-scrollbar-track {
  width: 5px;
  background: transparent;
}
.new-car-list:hover::-webkit-scrollbar-thumb {
  width: 5px;
  height: 30px;
  width: 5px;
  border-radius: 3.5px;
  background-color: #d8d8d8;
}
.new-car-title {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: #1f2129;
  height: 28px;
  margin-bottom: 16px;
}

.item-time-fix-wrap {
  position: absolute;
  top: 16px;
  left: 16px;
  right: 0;
  z-index: 9;
}
.car-image-wrap {
  position: absolute;
  width: 152px;
  height: 106px;
  background-position: 0 -848px;
  background-repeat: no-repeat;
  background-size: 152px 1060px;
  @apply tw-right-0
  tw-top-0
  tw--right-73
  sm:tw--right-73
  md:tw-right-0;
}
.new-car-item {
  position: relative;
  max-height: 168px;
  font-size: 12px;
  background: #fff;
  border-radius: 4px;
  color: #1f2129;
  margin-bottom: 8px;
  padding-bottom: 8px;
  transition: box-shadow ease-in-out 0.1s;
  overflow: hidden;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(31, 33, 41, 0.12);
  }

  :global(.car-image-type-0) {
    background-position: 0 0;
  }
  :global(.car-image-type-1) {
    background-position: 0 -318px;
  }
  :global(.car-image-type-2) {
    background-position: 0 -424px;
  }
  :global(.car-image-type-3) {
    background-position: 0 -212px;
  }
  :global(.car-image-type-4) {
    background-position: 0 -530px;
  }
  :global(.car-image-type-6) {
    background-position: 0 -746px;
  }
  :global(.car-image-type-7) {
    background-position: 0 -954px;
  }
  :global(.car-image-type-8) {
    background-position: 0 -636px;
  }
  :global(.car-image-type-ev) {
    background-position: 0 -106px;
  }
}
.item-time-wrap {
  display: inline-block;
  vertical-align: middle;
  height: 19px;
  padding: 1px 4px;
  border-radius: 2px;
  background-color: #f2f4fa;
  font-size: 12px;
  font-weight: 600;
  color: #1f2129;
}
.online .item-time-wrap {
  background-color: #1f2129;
  color: #f7f8fc;
}
.item-tag {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
  height: 19px;
  padding: 1px 4px;
  border-radius: 2px;
  background-color: #00a860;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
}
.item-time-link {
  height: 106px;
  padding: 36px 16px 0 16px;
  display: block;
  position: relative;
}
.item-time-link:before {
  content: '';
  height: 1px;
  background: #e6e8f2;
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 0;
  z-index: 9;
}
.item-time-status {
  padding-left: 4px;
}
.item-time-status-before {
  display: inline-block;
  position: absolute;
  margin-top: -3px;
}

.item-title-wrap {
  margin-top: 8px;
  margin-left: 0;
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2129;
  position: relative;
  @apply sm:tw-mr-75
  md:tw-mr-150;
}
.item-title {
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}
.item-title.hastag {
  max-width: 100%;
}


.item-title-wrap .tag {
  text-align: center;
  padding: 2px 4px;
  border-radius: 2px;
  display: inline-block;
  background: rgba(230, 88, 0, 0.1);
  font-size: 12px;
  color: #b37d12;
  transform: scale(0.8);
  vertical-align: middle;
  margin: 0;
  top: 3px;
  position: absolute;
  margin-left: -3px;
}
.tag-width-1 {
  width: 35px;
}
.tag-width-2 {
  width: 40px;
}
.tag-width-3 {
  width: 45px;
}
.tag-max-width {
  width: 60px;
}


.item-price {
  padding: 0 0 8px 0;
  font-family: PingFangSC;
  font-size: 14px;
  font-weight: 600;
  color: #e62021;
}
.no-price {
  color: #979aa8 !important;
}
.new-car-item .homepage-new-car-img {
  position: absolute;
  width: 126px;
  height: 84px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 0;
  @apply tw-right-5
  tw-top-19
  md:tw-right-2
  lg:tw-right-8;
}
.item-one-line-text {
  margin: 2px 2px 0 0;
  font-size: 12px;
  color: #979aa8;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
.item-community {
  height: 24px;
  position: relative;
  padding: 4px 8px 4px 16px;
  cursor: pointer;
}
.item-article {
  position: relative;
  padding-left: 16px;
  padding-right: 8px;
  cursor: pointer;
}
.max-width {
  max-width: calc(100% - 39px);
  display: inline-block;
}
.more {
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 4px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid #979aa8;
  vertical-align: super;
}
.item-article:hover span,
.item-community:hover span {
  color: #1f2129;
  transition: color 0.5s;
}
.item-article:hover .more,
.item-community:hover .more {
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 5px solid #1f2129;
}

.new-car-empty {
  position: relative;
  height: 152px;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
}