@import '@byted-motor/pc-components/dist/styles/variable.scss';
.wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.inner {
  font-size: 16px;
  transform: scale(0.625);
  white-space: nowrap;
}