.title-wrapper{
  display:flex;
  align-items:center
}

.module-title{
  font-weight:500;
  height:28px;
  line-height:28px;
  font-size:16px
}

@media (min-width: 1440px){
  .module-title{
    font-size:18px
  }
}

@media (min-width: 1680px){
  .module-title{
    font-size:20px
  }
}