.brand-list{
  display:flex
}

.brand-title{
  width:45px;
  padding:10px 0 0 0;
  font-size:16px;
  color:#1f2129
}

.box{
  flex:1;
  overflow:hidden;
  height:70px;
  position:relative
}

.list-item-wrap{
  padding:0 40px;
  display:-ms-grid;
  display:grid;
  gap:12px;
  -ms-grid-columns:minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr);
  grid-template-columns:repeat(7, minmax(0, 1fr))
}

@media (min-width: 1280px){
  .list-item-wrap{
    -ms-grid-columns:(minmax(0, 1fr))[9];
    grid-template-columns:repeat(9, minmax(0, 1fr))
  }
}

@media (min-width: 1680px){
  .list-item-wrap{
    -ms-grid-columns:(minmax(0, 1fr))[13];
    grid-template-columns:repeat(13, minmax(0, 1fr))
  }
}

@media (min-width: 1920px){
  .list-item-wrap{
    -ms-grid-columns:(minmax(0, 1fr))[16];
    grid-template-columns:repeat(16, minmax(0, 1fr))
  }
}

.model{
  flex:1;
  text-align:center;
  margin-top:-4px
}

.brand-img-wrap{
  width:40px;
  height:40px;
  margin:0 auto;
  border-radius:50%
}

.model:hover .brand-name{
  color:#e62021
}

.brand-wrap{
  display:inline-block;
  width:100%;
  text-align:center;
  height:40px;
  transition:all .1s ease-in-out
}

.brand-name{
  color:#1f2129;
  font-size:12px;
  width:100%
}

.brand-name>span{
  position:relative
}

.brand-ad-tag{
  position:absolute;
  right:-20px;
  top:-1px
}