@import '@byted-motor/pc-components/dist/styles/variable.scss';
.brand-list {
  display: flex;
}
.brand-title {
  width: 45px;
  padding: 10px 0 0 0;
  font-size: 16px;
  color: #1f2129;
}
.box {
  flex: 1;
  overflow: hidden;
  height: 70px;
  position: relative;
}
.list-item-wrap {
  padding: 0 40px;
  @apply tw-grid tw-gap-12 tw-grid-cols-7 md:tw-grid-cols-9 xl:tw-grid-cols-13 2xl:tw-grid-cols-16;
}

.model {
  flex: 1;
  text-align: center;
  margin-top: -4px;
}
.brand-img-wrap {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
}
.model:hover .brand-name {
  color: #e62021;
}
.brand-wrap {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 40px;
  transition: all 0.1s ease-in-out;
}
.brand-name {
  color: #1f2129;
  font-size: 12px;
  width: 100%;
}
.brand-name>span {
  position: relative;
}
.brand-ad-tag {
  position: absolute;
  right: -20px;
  top: -1px;
}