@import '@byted-motor/pc-components/dist/styles/variable.scss';
.root {
  width: 84.44%;
  min-width: 556px;
  margin: -20px auto 20px;
  display: block;
  border-radius: 2px;
  .info {
    display: flex;
    align-items: center;
    padding: 12px 16px 10px;
    background-color: #FFF;
    border-radius: 2px 2px 0 0;
    .info-series {
      color: #000;
      &:hover {
        @apply tw-text-color-orange-500;
      }
      .info-series-name {
        line-height: 28px;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .info-price {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .info-price-item {
        font-size: 14px;
        line-height: 22px;
        margin-right: 24px;
        .info-price-label {
          @apply tw-text-color-gray-700;
        }
        .info-price-dealer {
          font-weight: 600;
          @apply tw-text-color-red-500;
        }
        .info-price-cut {
          @apply tw-text-common-black;
        }
        .info-price-cut-icon {
          @apply tw-text-green-500;
        }
      }
    }
    .info-link-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
      .info-link-anchor {
        display: block;
        margin-left: 6px;
        &:global(.disabled) {
          .info-link {
            @apply tw-text-color-gray-600 tw-bg-color-gray-400 tw-border-color-gray-400;
          }
        }
      }
      .info-link {
        display: block;
        font-size: 14px;
        padding: 0px 20px;
        line-height: 30px;
        border-radius: 2px;
        border: 1px solid;
        white-space: nowrap;
        @apply tw-text-color-gray-900;
        &.info-link-normal {
          @apply tw-border-color-gray-600;
        }
        &.info-link-highlight {
          @apply tw-border-common-yellow tw-bg-common-yellow;
        }
        &:hover {
          @apply tw-text-color-red-500;
        }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 0 0 2px 2px;
    background-color: #fff;
    padding: 0 16px;
    .footer-link-anchor {
      display: block;
      padding: 12px 0 10px;
      @apply tw-text-color-gray-800;
      & ~ .footer-link-anchor {
        margin-left: 18px;
      }
      &:global(:not(.disabled):hover) {
        @apply tw-text-color-red-500;
        .footer-link-icon,
        .footer-link-subtext {
          color: currentColor;
        }
      }
    }
    .footer-link {
      display: inline-flex;
      flex-wrap: wrap;
      font-size: 14px;
      line-height: 22px;
      .footer-link-subtext {
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        padding: 0 2px;
        @apply tw-text-common-black;
      }
      .footer-link-icon {
        @apply tw-text-color-gray-900;
      }
    }
  }
  .none {
    padding: 0 2px;
    font-size: 14px;
    line-height: 22px;
    @apply tw-text-color-gray-700;
  }
}