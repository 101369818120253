@import '@byted-motor/pc-components/dist/styles/variable.scss';
.name:global(.line-1) {
  color: #969aa9;
  &:hover {
    @apply tw-text-common-orange;
  }
}

.wrap {
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  display: flex;
  width: 92px;
  justify-content: center;
  margin: 2px auto;
  color: #999999;
  li {
    padding: 0 4px;
    position: relative;
  }
  li:first-child {
    padding-left: 0;
  }

  li:not(:last-child)::after {
    content: '';
    top: 4px;
    right: 0;
    background-color: #cccccc;
    height: calc(100% - 8px);
    width: 1px;
    position: absolute;
  }
}
.model {
  flex: 1;
  padding: 0 3px;
}
.name-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  height: 22px;
  line-height: 22px;
}
.series-name {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  transition: all 0.3s ease-in-out;
}
.series-name:hover {
  color: #e62021;
}
.tag {
  position: relative;
  display: inline-block;
  padding: 0 4px;
  height: 19px;
  margin-left: 4px;
  top: 2px;
  line-height: 19px;
  color: #ffffff;
  border-radius: 2px;
  background-color: #e65800;
  font-size: 12px;
  transform: scale(0.8);
}