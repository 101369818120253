.choose-car-wrap{
  padding-bottom:24px;
  display:-ms-grid;
  display:grid;
  width:100%;
  gap:12px;
  -ms-grid-columns:minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr) 12px minmax(0, 1fr);
  grid-template-columns:repeat(4, minmax(0, 1fr))
}

@media (min-width: 1920px){
  .choose-car-wrap{
    -ms-grid-columns:(minmax(0, 1fr))[5];
    grid-template-columns:repeat(5, minmax(0, 1fr))
  }
}

.entry{
  background:linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  border-radius:2px;
  margin-bottom:4px
}