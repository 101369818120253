@import '@byted-motor/pc-components/dist/styles/variable.scss';
.car-list-loading {
  height: 180px;
  padding-top: 60px;
}
.popular-model {
  height: 322px;
  border-top: 0;
  border-radius: 4px;
  background: #fff;
  padding: 12px 8px 22px 16px;

  :global(.cus-button-next-click) {
    position: absolute;
    font-size: 12px;
    color: #606370;
    right: 10px;
    top: 13px;
    z-index: 9;
  }
  :global(.cus-button-next-click:hover) {
    color: #e62021;
    cursor: pointer;
  }
  :global(.cus-button-next-click a:after) {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #1f2129;
    margin-left: 4px;
    margin-bottom: 1px;
  }
  :global(.cus-button-next-click:hover a:after) {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #e62021;
    margin-left: 4px;
    margin-bottom: 1px;
  }
}
.car-title {
  width: 45px;
  padding: 10px 0 0 0;
  font-size: 16px;
  color: #1f2129;
}

.j-condition-list {
  position: relative;
  margin-bottom: 16px;
  height: 37px;
  margin-left: -10px;
  padding-left: 10px;
  margin-right: -10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    left: 7px;
    right: 17px;
    bottom: 0;
    height: 1px;
    background: #e6e8f2;
  }
  li {
    position: relative;
    display: inline-block;
    height: 36px;
    line-height: 36px;
  }
  li:hover {
    cursor: pointer;
  }
  li:last-child {
    margin-right: 0;
  }

  .item:hover {
    color: #1f2129;
  }
  .active {
    font-weight: 600;
  }
}
.scroll-box {
  position: absolute;
  width: 32px;
  height: 3px;
  background: #ffcc32;
  bottom: 0;
  left: 0;
  transform: translateX(10px);
  transition: transform 0.4s ease-in-out, width 0.4s ease-in-out;
}