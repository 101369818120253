.wrap{
  padding-bottom:39.54%;
  height:0px;
  overflow:hidden;
  position:relative;
  -ms-grid-column-span:20;
  grid-column:span 20 / span 20
}

@media (min-width: 1920px){
  .wrap{
    -ms-grid-column-span:16;
    grid-column:span 16 / span 16
  }
}

.content{
  margin-right:-20px;
  overflow:hidden;
  position:absolute;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px
}

.item{
  margin-right:20px;
  position:relative;
  vertical-align:top;
  display:inline-flex;
  align-items:center;
  font-size:14px
}

@media (min-width: 1280px){
  .item{
    font-size:16px
  }
}

.item.large{
  font-weight:600;
  font-size:20px
}

@media (min-width: 1280px){
  .item.large{
    font-size:22px
  }
}

@media (min-width: 1680px){
  .item.large{
    font-size:24px
  }
}

.item::before{
  content:"";
  position:absolute;
  left:-11px;
  width:1px;
  height:12px;
  background-color:#c9cbd6;
  z-index:10
}

.item:hover{
  color:#e65800
}

.item:hover .video-icon{
  background:#e65800
}

.hot{
  color:#b02425
}

.hot-bg{
  background:#b02425
}

.hot:hover{
  color:#e65800
}

.video-icon{
  margin-right:3px;
  display:inline-flex;
  align-items:center;
  justify-content:center;
  width:14px;
  height:14px;
  border-radius:14px
}

.more{
  float:right;
  margin-right:20px;
  display:inline-flex;
  align-items:center;
  --tw-text-opacity:1;
  color:rgba(32, 91, 230, var(--tw-text-opacity))
}

.more:hover{
  --tw-text-opacity:1;
  color:rgba(230, 88, 0, var(--tw-text-opacity))
}

.item-h{
  height:calc(100% / 5)
}

@media (min-width: 1280px){
  .item-h{
    height:calc(100% / 6)
  }
}

@media (min-width: 1440px){
  .item-h{
    height:calc(100% / 7)
  }
}

@media (min-width: 1680px){
  .item-h{
    height:calc(100% / 8)
  }
}